import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SplashCta from '../component/cta/SplashCta';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';


const PrivacyPolicy = () => {

    return (
        <>
            <SEO title="Privacy Policy" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BreadCrumbOne 
                title="Privacy Policy"
                page="Privacy Policy"
                />
                <div className="section-padding privacy-policy-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="privacy-policy-content">
                                <div className="section-title">
                                    <h5 className="title">This Privacy policy was published on March 10th, 2023.</h5>
                                </div>
                                <p>MyOnly Management ("we," "us," or "our") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, and disclose your personal information when you use our website and services. By using our website and services, you agree to the collection and use of your personal information in accordance with this Privacy Policy.</p>
                                <h4>Information We Collect</h4>
                                <p>We may collect the following types of personal information from you:</p>
                                <ul>
                                    <li>a. Personal Information: This includes information such as your name, email address, mailing address, phone number, and other information that you provide when you create an account, contact us, or use our services.</li>
                                    <li>b. Usage Information: We automatically collect information about your interactions with our website and services, such as the pages you visit, the content you view, and the actions you take.</li>
                                    <li>c. Device Information: We may collect information about the device you use to access our website and services, such as your device's IP address, browser type, operating system, and other device identifiers.</li>
                                    <li>d. Cookies and Similar Technologies: We use cookies and similar technologies to collect information about your use of our website and services and to remember your preferences.</li>
                                </ul>
                                <h4>How We Use Your Information</h4>
                                <p>We may collect the following types of personal information from you:</p>
                                <ul>
                                    <li>a. Provide, maintain, and improve our website and services.</li>
                                    <li>b. Communicate with you, respond to your inquiries, and provide customer support.</li>
                                    <li>c. Personalise your experience on our website and services by presenting tailored content, recommendations, and advertisements.</li>
                                    <li>d. Monitor and analyse trends, usage, and activities in connection with our website and services.</li>
                                    <li>e. Detect, investigate, and prevent fraudulent transactions and other illegal activities, and protect our rights and the rights of our users.</li>
                                    <li>f. Comply with legal obligations, resolve disputes, and enforce our agreements, including our Terms of Use.</li>
                                </ul>
                                <h4>How We Share Your Information</h4>
                                <p>We may share your personal information with third parties in the following circumstances:</p>
                                <ul>
                                    <li>a. With service providers that perform our behalf, such as hosting providers, payment processors, and marketing partners.</li>
                                    <li>b. With third-party platforms, such as OnlyFans, when you use our services to manage your content on these platforms.</li>
                                    <li>c. In response to a request for information if we believe disclosure is in accordance with, or required by, any applicable law, regulation, or legal process.</li>
                                    <li>d. If we believe your actions are inconsistent with our Terms of Use or other policies, or to protect the rights, property, or safety of MyOnly Management or others.</li>
                                    <li>e. In connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business by another company.</li>
                                    <li>f. With your consent or at your direction.</li>
                                </ul>
                                <h4>GDPR Compliance</h4>
                                <p>In compliance with the General Data Protection Regulation (GDPR), if you are a resident of the European Economic Area (EEA), you have the following rights regarding your personal information:</p>
                                <ul>
                                    <li>a. Right to access: You have the right to request access to the personal information we hold about you.</li>
                                    <li>b. Right to rectification: You have the right to request that we correct any inaccuracies in your personal information.</li>
                                    <li>c. Right to erasure: You have the right to request that we delete your personal information, subject to certain legal limitations.</li>
                                    <li>d. Right to restrict processing: You have the right to request that we restrict the processing of your personal information under certain circumstances.</li>
                                    <li>e. Right to data portability: You have the right to request a copy of your personal information in a structured, commonly used, and machine-readable format, and to have it transferred to another data controller.</li>
                                    <li>f. Right to object: You have the right to object to our processing of your personal information under certain circumstances.</li>
                                </ul>
                                <p>To exercise any of these rights, please contact us at hello@myonlymanagement.com. We may require you to verify your identity before processing your request.</p>
                                <h4>Data Retention</h4>
                                <p>We retain your personal information for as long as necessary to fulfill the purposes for which it was collected, including for the purposes of satisfying any legal, accounting, or reporting requirements. To determine the appropriate retention period for personal information, we consider the amount, nature, and sensitivity of the information, the potential risk of harm from unauthorised use or disclosure, the purposes for which we process it, and whether we can achieve those purposes through other means, as well as the applicable legal requirements.</p>
                                <h4>Security</h4>
                                <p>We take appropriate technical and organisational measures to protect your personal information from unauthorised access, use, disclosure, alteration, and destruction. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee the absolute security of your personal information.</p>
                                <h4>Third-Party Links and Services</h4>
                                <p>Our website and services may contain links to third-party websites, services, or resources. This Privacy Policy does not apply to the information collected by these third parties. We encourage you to review the privacy policies of any third-party websites or services you visit.</p>
                                <h4>Changes to This Privacy Policy</h4>
                                <p>We reserve the right to update or modify this Privacy Policy at any time without prior notice. Your continued use of our website and services following the posting of any changes to this Privacy Policy constitutes acceptance of those changes. We encourage you to review this Privacy Policy periodically for any updates or changes.</p>
                                <h4>Contact Information</h4>
                                <p>If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at:</p>
                                <p>Email: hello@myonlymanagement.com</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <SplashCta />
            <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default PrivacyPolicy;