import React from 'react';
import FormTwo from '../contact/FormTwo';


const AboutOne = () => {
    return (
      <section className="section section-padding-equal bg-color-light">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-us">
                <div className="section-heading heading-left mb-0">
                  <span className="subtitle">About Us</span>
                  <h2 className="title mb--40">
                    We save you time and build your brand.
                  </h2>
                  <p>
                    myOnly knows how difficult and complex the digital space can
                    be to manage, especially on your own. That’s why we will
                    work to offload any unnecessary labor, managing your
                    accounts by your specifications, so you can spend your time
                    on more important matters.{" "}
                  </p>
                  <p>
                    If you’re ready to Realize Your True Potential. Get in touch, we are here
                    to help you every step of the way.{" "}
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xl-5 col-lg-6 offset-xl-1">
              <div className="contact-form-box">
                <h3 className="title">Join a Discovery Call Today.</h3>
                <FormTwo />
              </div>
            </div>
          </div>
        </div>
        <ul className="shape-group-6 list-unstyled">
          <li className="shape shape-1">
            <img
              src={process.env.PUBLIC_URL + "/images/others/bubble-7.png"}
              alt="Bubble"
            />
          </li>
          <li className="shape shape-2">
            <img
              src={process.env.PUBLIC_URL + "/images/others/line-4.png"}
              alt="line"
            />
          </li>
          <li className="shape shape-3">
            <img
              src={process.env.PUBLIC_URL + "/images/others/line-5.png"}
              alt="line"
            />
          </li>
        </ul>
      </section>
    );
}

export default AboutOne;