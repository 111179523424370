import React from 'react';
import {Link, useParams} from 'react-router-dom';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import BcrumbBannerTwo from '../elements/breadcrumb/BcrumbBannerTwo';

import { slugify } from '../utils';
import ProjectData from "../data/project/ProjectData.json";

import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';

import SplashCta from "../component/cta/SplashCta";


const allProjectData = ProjectData;

const ProjectDetails = () => {

    const params = useParams();
    const projectSlug = params.slug;

    const getProjectData = allProjectData.filter(data => slugify(data.title) === projectSlug);
    const detailsProject = getProjectData[0];


    return (
      <>
        <SEO title="Carrers" />
        <ColorSwitcher />
        <main className="main-wrapper">
          <HeaderOne />
          <BcrumbBannerTwo
            title={detailsProject.title}
            paragraph={detailsProject.excerpt}
            mainThumb={detailsProject.image}
          />
          <section className="section-padding single-portfolio-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-5">
                  <div className="section-heading heading-left mb-0">
                    <span className="subtitle">
                      {detailsProject.category.map((cat, i) => (
                        <span key={i}>{cat}</span>
                      ))}
                    </span>
                    <h3 className="title">{detailsProject.title}</h3>
                  </div>
                  {detailsProject.body.map((para, index) => (
                    <p
                      key={index}
                      dangerouslySetInnerHTML={{ __html: para }}
                    ></p>
                  ))}
                  <Link to={process.env.PUBLIC_URL + "/contact"} className="axil-btn btn-fill-primary">
                    Apply Now!
                  </Link>
                </div>
                <div className="col-lg-6 offset-xl-1">
                  <div className="why-choose-us">
                    <div className="section-heading heading-left">
                      <h3 className="title">About Us</h3>
                      <p>MyOnly Management is a innovative company specializing in the management and growth of content creators on the renowned platform, OnlyFans. As one of the fastest growing management agency in the industry, myOnly consistently strives to provide unparalleled support and guidance to our creators, ensuring they thrive in the ever-evolving digital landscape.</p>
                      <p>At myOnly, we pride ourselves on our ability to foster a vibrant and dynamic work environment for our team members. We offer competitive salaries, which are designed to attract and retain the best talent in the industry. Our team members are encouraged to push boundaries, explore new opportunities, and contribute to our shared success.</p>
                      <p>In addition to our attractive base salaries, myOnly Management provides ample opportunities for exceptional bonuses, further incentivizing our employees to excel in their roles. We firmly believe that by rewarding exceptional performance, we foster a culture of dedication, innovation, and growth, benefiting both our clients and our team members. Join myOnly Management, and be part of the future of the thriving OnlyFans ecosystem.</p>
                    </div>
                    {/* <Accordion defaultActiveKey="1">
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>
                          <FaCompress /> Strategy
                        </Accordion.Header>
                        <Accordion.Body>
                          Aenean hendrerit laoreet vehicula. Nullam convallis
                          augue at enim gravida pellentesque.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>
                          <FaCode /> Design
                        </Accordion.Header>
                        <Accordion.Body>
                          Aenean hendrerit laoreet vehicula. Nullam convallis
                          augue at enim gravida pellentesque.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="3">
                        <Accordion.Header>
                          <FaGlobe /> Development
                        </Accordion.Header>
                        <Accordion.Body>
                          Aenean hendrerit laoreet vehicula. Nullam convallis
                          augue at enim gravida pellentesque.
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <VideoOne /> */}
          <SplashCta />
          <FooterOne parentClass="" />
        </main>
      </>
    );
}

export default ProjectDetails;