import React from 'react';
import { Link } from 'react-router-dom';
import { FaAngleDown } from "react-icons/fa";


const Nav = () => {
    return (
        <nav className="mainmenu-nav">
            <ul className="mainmenu">
                <li className="menu-item-has-children">
                    <Link to={process.env.PUBLIC_URL + "/"}>Home</Link>
                </li>
                <li className="menu-item-has-children">
                    <Link to="#">Services <FaAngleDown /></Link>
                    <ul className="axil-submenu">
                        <li><Link to={process.env.PUBLIC_URL + "/service-details/data-driven-analytics"}>Data Driven Analytics</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/service-details/content-creation"}>Content Creation</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/service-details/brand-and-account-management"}>Brand and Account Management</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/service-details/brand-growth"}>Brand Growth</Link></li>
                    </ul>
                </li>
                    <li className="menu-item-has-children">
                    <Link to={process.env.PUBLIC_URL + "/about-us"}>About Us</Link>
                </li>
                <li className="menu-item-has-children">
                    <Link Link to={process.env.PUBLIC_URL + "/jobs"}>We're Hiring!</Link>
                    {/* <ul className="axil-submenu">
                        <li><Link to={process.env.PUBLIC_URL + "/project-grid-one"}>Two Column</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/Jobs"}>Three Column</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/project-grid-three"}>Four Column</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/project-width-one"}>Three Column Width</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/project-width-two"}>Four Column Width</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/project-details/plan-management"}>Portfolio Details</Link></li>
                    </ul> */}
                </li>

                <li className="menu-item-has-children">
                    <Link to={process.env.PUBLIC_URL + "/blog-grid"}>Blog</Link>
                </li>
                <li><Link to={process.env.PUBLIC_URL + "/contact"}>Contact Us</Link></li>
            </ul>
        </nav>
    )
}

export default Nav;