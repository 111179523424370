import React from 'react';
import { Link } from 'react-router-dom';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { FaTiktok, FaTwitter, FaInstagram, FaTelegram, FaLaptop } from 'react-icons/fa';

const OffcanvasMenu = ({ offcanvasShow, offcanvasHide }) => {
  return (
    <Offcanvas show={offcanvasShow} onHide={offcanvasHide} placement="end" className="header-offcanvasmenu">
      <Offcanvas.Header closeButton></Offcanvas.Header>
      <Offcanvas.Body>
        <div className="row">
          <div className="col-lg-5 col-xl-6">
            <ul className="main-navigation list-unstyled">
              <li>
                <Link to={process.env.PUBLIC_URL + '/service-details/data-driven-analytics'}>
                  Data Driven Analytics
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + '/service-details/content-creation'}>
                  Content Creation
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + '/service-details/brand-and-account-management'}>
                  Brand and Account Management
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + '/service-details/brand-growth'}>
                  Brand Growth
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-7 col-xl-6">
            <div className="contact-info-wrap">
              <div className="contact-inner">
                <address className="address">
                  <span className="title">We're Available. Get in touch.</span>
                  <a href="mailto:hello@myonlymanagement.com" className="email">
                    <FaLaptop />hello@myonlymanagement.com
                  </a>
                </address>
              </div>
              <div className="contact-inner">
                <h5 className="title">Find us here</h5>
                <div className="contact-social-share">
                  <ul className="social-share list-unstyled">
                    <li>
                      <Link to="https://twitter.com/myonlymgmt">
                        <FaTwitter />
                      </Link>
                    </li>
                    <li>
                      <Link to="https://www.tiktok.com/@myonlymanagement">
                        <FaTiktok />
                      </Link>
                    </li>
                    <li>
                      <Link to="https://www.instagram.com/myonlymanagement/">
                        <FaInstagram />
                      </Link>
                    </li>
                    <li>
                      <Link to="https://t.me/myonlymanagement">
                        <FaTelegram />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default OffcanvasMenu;
