import React from 'react';
import SEO from '../common/SEO';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import FormTwo from '../component/contact/FormTwo';
import BcrumbBannerOne from "../elements/breadcrumb/BcrumbBannerOne";


import { Link } from 'react-router-dom';
import {FaTelegram, FaWhatsapp} from "react-icons/fa";

const Contact = () => {
    return (
      <>
        <SEO title="Blog Grid" />
        <ColorSwitcher />

        <main className="main-wrapper">
          <HeaderOne />
          <BcrumbBannerOne
            title="Contact Us!"
            paragraph="Get in touch for a Discovery Call. Let's see if we are a fit."
            styleClass=""
            mainThumb="/images/banner/banner-thumb-1.png"
          />

          <div className="section section-padding">
            <div className="container">
              <div className="row">
                <div className="col-xl-5 col-lg-6">
                  <div className="contact-form-box shadow-box mb--30">
                    <h3 className="title">Get in Touch today.</h3>
                    <FormTwo />
                  </div>
                </div>
                <div className="col-xl-5 col-lg-6 offset-xl-1">
                  <div className="contact-info mb--100 mb_md--30 mt_md--0">
                    <h4 className="title">Phone</h4>
                       <p>
                        Get In touch on Telegram or Whatsup for any questions.
                     </p>
                     <h4 className="phone-number w-75 img-fluid">
                          <Link to="https://t.me/myonlymanagement">
                              <FaTelegram className="telegram-icon" />
                          </Link>
                          <Link to="https://wa.me/07757911706">
                          <FaWhatsapp className="telegram-icon" style={{marginLeft: "53px"}} />
                          </Link>
                      </h4>
                  </div>
                  <div className="contact-info mb--30">
                    <h4 className="title">Email</h4>
                       <p>Our support team will get back as soon as possible.</p>
                         <h5 className="phone-number">
                            <a href="mailto:hello@myonlymanagement.com">
                             hello@myonlymanagement.com
                           </a>
                        </h5>
                  </div>
                </div>
              </div>
            </div>
            <ul className="list-unstyled shape-group-12">
              <li className="shape shape-1">
                <img
                  src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"}
                  alt="Bubble"
                />
              </li>
              <li className="shape shape-2">
                <img
                  src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"}
                  alt="Bubble"
                />
              </li>
              <li className="shape shape-3">
                <img
                  src={process.env.PUBLIC_URL + "/images/others/circle-3.png"}
                  alt="Circle"
                />
              </li>
            </ul>
          </div>

          {/* <div className="section section-padding bg-color-dark overflow-hidden">
            <div className="container">
              <SectionTitle
                subtitle="Find Us"
                title="Our office"
                description=""
                textAlignment="heading-light-left"
                textColor=""
              />
              <div className="row">
                <ContactLocation />
              </div>
            </div>
            <ul className="shape-group-11 list-unstyled">
              <li className="shape shape-1">
                <img
                  src={process.env.PUBLIC_URL + "/images/others/line-6.png"}
                  alt="line"
                />
              </li>
              <li className="shape shape-2">
                <img
                  src={process.env.PUBLIC_URL + "/images/others/circle-3.png"}
                  alt="line"
                />
              </li>
            </ul>
          </div> */}

          <FooterOne parentClass="pt--150 pt_lg--100 pt_md--80 pt_sm--60" />
        </main>
      </>
    );
}

export default Contact;