import React from 'react';
import { Link } from 'react-router-dom';

const CounterUpTwo = () => {
    return (
      <div className="section section-padding expert-counterup-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <div className="section-heading heading-left">
                <span className="subtitle">Experts in field</span>
                <h2>What makes us special?</h2>
                <p className="mb--50">
                  We brand people, not businesses. Our team boasts years of expertise in digital marketing and
                  model management. More importantly, we focus on building a
                  team around your specific needs to establish a strong brand
                  presence within your niche.
                </p>
                <Link
                  to={process.env.PUBLIC_URL + "/contact"}
                  className="axil-btn btn-large btn-fill-primary"
                >
                  Get in touch
                </Link>
              </div>
            </div>
            <div className="col-lg-6 offset-xl-1">
              <div className="mx-auto w-50">
                {/* <CounterUp
                  colSize="col-sm-6"
                  layoutStyle="counterup-style-2"
                  evenTopMargin=""
                /> */}
                <img
                  src={
                    process.env.PUBLIC_URL + "/images/banner/banner-thumb-1.png"
                  }
                  alt="myOnly"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}


export default CounterUpTwo;