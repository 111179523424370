import React, {useRef, useState} from 'react';
import emailjs from '@emailjs/browser';
import Alert from 'react-bootstrap/Alert';

const Result = () => {
    return (
        <Alert variant="success" className="success-msg">
        Your Message has been successfully sent.
        </Alert>
    )
}

const SubscriptionCTA = () => {

    const form = useRef();

    const [ result, showresult ] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_l2neuqe', 'template_07cc5ff', form.current, '4zovE0wW80eG6Dvp8')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
          form.current.reset();
          showresult(true);
      };

        setTimeout(() => {
            showresult(false);
        }, 5000);


    return (
      <form ref={form} onSubmit={sendEmail} className="axil-contact-form">

        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            className="form-control"
            name="contact-email"
            required
          />
        </div>
        <div className="form-group">
          <button
            type="submit"
            className="axil-btn btn-fill-primary btn-fluid btn-primary"
            name="submit-btn">Submit</button>
        </div>
        <div className="form-group">{result ? <Result /> : null}</div>
      </form>
    );
}

export default SubscriptionCTA
;