import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';

const Datas = [
  {
    id: 1,
    title: "Personalized and Creator-Focused",
    para: "myOnly Management, provides a personalized and creator-focused approach to management. We prioritize your vision, goals, and interests, ensuring that our services are tailored to your unique needs.",
  },
  {
    id: 2,
    title: "Transparent and Ethical Practices",
    para: "At myOnly Management, we uphold transparent and ethical practices in all our dealings with you. You can expect clear payment arrangements, performance metrics, and reporting. We conduct our business in a fair and ethical manner.",
  },
  {
    id: 3,
    title: "Innovative and Holistic Approach",
    para: "We have an innovative and holistic approach, utilizing the latest trends and technologies to maximize your revenue and engagement. We take a holistic approach to management, focusing not just on revenue but also on building a loyal fan base.",
  },
  {
    id: 4,
    title: "Excellent Communication and Ongoing Support",
    para: "We have excellent communication skills and provide regular updates, feedback, and guidance to you. You'll always be in the loop about your account's performance and any new strategies we're implementing. We also provide ongoing support to you, including advice, guidance, and mentorship.",
  },
  {
    id: 5,
    title: "Data-driven insights",
    para: "We utilize data-driven insights to optimize your performance, engagement, and revenue. We're always analyzing data to help you achieve better results. However, we will never use any automation or scripts to speak to clients. All are interactions are made by very well paid chatters based in the UK and the United States.",
  },
  {
    id: 6,
    title: "Partnership and Collaborative Approach",
    para: "We approach our relationship with you as a partnership, working collaboratively to achieve success. We're invested in your growth and will work tirelessly to help you achieve your goals. We're always open to your feedback and suggestions and value your input.",
  },
];




const AboutThree = () => {
    return (
        <div className="section section-padding bg-color-dark pb--80 pb_lg--40 pb_md--20">
            <div className="container">
                <SectionTitle 
                    subtitle="Our Values"
                    title="Why should you work with us?"
                    description=""
                    textAlignment="heading-left heading-light-left mb--100"
                    textColor=""
                />

                <div className="row">
                    {Datas.map((data) => (
                        <div className="col-lg-4" key={data.id}>
                            <div className="about-quality">
                                <h3 className="sl-number">{data.id}</h3>
                                <h5 className="title">{data.title}</h5>
                                <p>{data.para}</p>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
            <ul className="list-unstyled shape-group-10">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-1.png"} alt="Circle" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-3.png"} alt="Circle" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-5.png"} alt="Circle" /></li>
            </ul>
        </div>
    )
}

export default AboutThree;