import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SplashCta from '../component/cta/SplashCta';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';


const TermsOfUse = () => {

    return (
        <>
            <SEO title="Terms Of Use" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BreadCrumbOne 
                title="Terms Of Use"
                page="Terms Of Use"
                />
                <div className="section-padding privacy-policy-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="privacy-policy-content">
                                <div className="section-title">
                                    <h5 className="title">This Terms Of Use was published on February 24th, 2023.</h5>
                                </div>
                                <p>Welcome to the MyOnly Management website. MyOnly Management ("we," "us," or "our") provides services related to the management and promotion of content creators on the OnlyFans platform. By using our website and services, you ("you," "your," or "user") agree to be bound by the following terms of use ("Terms of Use").</p>
                                <p>Please read these Terms of Use carefully before using our website and services. If you do not agree with any part of these Terms of Use, you must not use our website or services.</p>
                                <h4>Eligibility</h4>
                                <p>By using our website and services, you represent and warrant that you are at least 18 years of age and have the legal capacity to enter into a binding contract. If you are using our website and services on behalf of a company or other legal entity, you represent and warrant that you have the authority to bind that entity to these Terms of Use.</p>
                                <h4>Account Registration</h4>
                                <p>To access certain features of our website and services, you may be required to create an account. You agree to provide accurate, current, and complete information during the account registration process and to update such information as necessary to keep it accurate, current, and complete. You are responsible for safeguarding your account information and for any activities that occur under your account. You agree to notify us immediately if you suspect any unauthorised use of your account or any other breach of security.</p>
                                <h4>Services</h4>
                                <p>MyOnly Management offers a range of services related to the management and promotion of content creators on the OnlyFans platform. These services may include, but are not limited to, content creation assistance, marketing and promotional support, and account management. We reserve the right to modify, suspend, or discontinue any of our services at any time, with or without notice.</p>
                                <h4>Fees and Payments</h4>
                                <p>Some of our services may require payment of fees. By using our paid services, you agree to pay all applicable fees and authorise us to charge your chosen payment method. All fees are non-refundable, except as required by law or explicitly stated in these Terms of Use.</p>
                                <h4>Intellectual Property</h4>
                                <p>All content on our website, including but not limited to text, images, graphics, logos, and software, is the property of MyOnly Management or its licensors and is protected by copyright, trademark, and other intellectual property laws. You agree not to reproduce, distribute, modify, or create derivative works from any content on our website without our express written permission.</p>
                                <h4>User Content</h4>
                                <p>By submitting, posting, or displaying content on or through our website or services, you grant us a worldwide, non-exclusive, royalty-free license to use, copy, reproduce, process, adapt, modify, publish, transmit, display, and distribute such content in any and all media or distribution methods. You represent and warrant that you have all the rights, power, and authority necessary to grant the rights granted herein to any content that you submit.</p>
                                <h4>Third-Party Links and Services</h4>
                                <p>Our website and services may contain links to third-party websites, services, or resources. We do not endorse and are not responsible or liable for any content, advertising, products, or other materials on or available from such third-party websites or services. You acknowledge and agree that MyOnly Management is not responsible or liable for any loss or damage resulting from your use of or reliance on any such third-party websites or services.</p>
                                <h4>Termination</h4>
                                <p>We may, in our sole discretion, terminate or suspend your access to our website and services at any time, with or without notice, for any reason, including but not limited to your violation of these Terms of Use.</p>
                                <h4>Disclaimer of Warranties and Limitation of Liability</h4>
                                <p>Our website and services are provided on an "as is" and "as available" basis without any warranties of any kind, express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, or non-infringement. To the fullest extent permitted by law, MyOnly Management disclaims all warranties, whether express or implied.</p>
                                <p>MyOnly Management does not warrant that our website or services will be uninterrupted, secure, or error-free, nor do we make any warranty as to the accuracy, completeness, or reliability of any content available through our website or services. You are solely responsible for verifying any information before relying on it.</p>
                                <p>In no event shall MyOnly Management, its affiliates, agents, directors, employees, suppliers, or licensors be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use our website or services; (ii) any conduct or content of any third party on our website or services, including without limitation, any defamatory, offensive, or illegal conduct of other users or third parties; (iii) any content obtained from our website or services; and (iv) unauthorised access, use, or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence), or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.</p>
                                <h4>Indemnification</h4>
                                <p>You agree to defend, indemnify, and hold harmless MyOnly Management, its affiliates, licensors, and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to your violation of these Terms of Use or your use of our website or services, including, but not limited to, your user content, any use of our website's content, services, and products other than as expressly authorized in these Terms of Use, or your use of any information obtained from our website.</p>
                                <h4>Governing Law and Jurisdiction</h4>
                                <p>These Terms of Use and any dispute or claim arising out of or related to them, their subject matter, or their formation (in each case, including non-contractual disputes or claims) shall be governed by and construed in accordance with the laws of England and Wales, without giving effect to any choice or conflict of law provision or rule.</p>
                                <p>Any legal suit, action, or proceeding arising out of or related to these Terms of Use or our website or services shall be instituted exclusively in the courts of England and Wales located in London, although we retain the right to bring any suit, action, or proceeding against you for breach of these Terms of Use in your country of residence or any other relevant country. You waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts.</p>
                                <h4>Changes to Terms of Use</h4>
                                <p>We reserve the right to update or modify these Terms of Use at any time without prior notice. Your continued use of our website or services following the posting of any changes to these Terms of Use constitutes acceptance of those changes. We encourage you to review these Terms of Use periodically for any updates or changes.</p>
                                <h4>Contact Information</h4>
                                <p>If you have any questions or concerns about these Terms of Use, please contact us at hello@myonlymanagement.com.</p>
                                <h4>Severability</h4>
                                <p>If any provision of these Terms of Use is held by a court or other tribunal of competent jurisdiction to be invalid, illegal, or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of these Terms of Use will continue in full force and effect.</p>
                                <h4>Entire Agreement</h4>
                                <p>These Terms of Use and any other policies or guidelines posted on our website, including our Privacy Policy, constitute the entire agreement between you and MyOnly Management with respect to your use of our website and services, and supersede all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral, with respect to the website and services.</p>
                                <h4>Waiver</h4>
                                <p>No waiver by MyOnly Management of any term or condition set forth in these Terms of Use shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure by MyOnly Management to assert a right or provision under these Terms of Use shall not constitute a waiver of such right or provision.</p>
                                <h4>Force Majeure</h4>
                                <p>MyOnly Management shall not be liable for any failure or delay in the performance of its obligations under these Terms of Use due to circumstances beyond its reasonable control, including but not limited to acts of God, war, terrorism, riots, embargoes, acts of civil or military authorities, fire, floods, accidents, strikes, or shortages of transportation facilities, fuel, energy, labor, or materials.</p>
                                <h4>Assignment</h4>
                                <p>You may not assign your rights or delegate your obligations under these Terms of Use without our prior written consent. We may assign our rights or delegate our obligations under these Terms of Use in our sole discretion.</p>
                                <h4>Headings</h4>
                                <p>The headings in these Terms of Use are for reference purposes only and shall not affect the interpretation or construction of these Terms of Use.</p>
                                <h4>Notices</h4>
                                <p>All notices or other communications required or permitted by these Terms of Use shall be in writing and shall be deemed given when delivered personally, sent by registered or certified mail (postage prepaid, return receipt requested), or sent by email to the parties at the addresses specified in these Terms of Use or at such other address as either party may specify in writing.</p>
                                <p>If you have any questions or concerns about these Terms of Use, please contact us at:</p>
                                <p>Email: hello@myonlymanagement.com</p>
                                <p>By using our website and services, you acknowledge that you have read and understood these Terms of Use and agree to be bound by them.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <SplashCta />
            <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default TermsOfUse;