import React from 'react';
import { Link } from 'react-router-dom';


const AboutFour = () => {
    return (
      <div className="section section-padding case-study-featured-area">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-6">
              <div className="case-study-featured-thumb text-start">
                <img
                  src={
                    process.env.PUBLIC_URL + "/images/others/case-study-4.png"
                  }
                  alt="myOnly"
                />
              </div>
            </div>
            <div className="col-xl-5 col-lg-6">
              <div className="case-study-featured">
                <div className="section-heading heading-left">
                  <span className="subtitle">Who we are</span>
                  <h2 className="title">The myOnly story</h2>
                  <p>
                    Welcome to myOnly management! Our team is dedicated to
                    helping creators achieve their full potential on the
                    platform. As the founder of this company, I have a unique
                    story to share with you.
                  </p>
                  <p>
                    I first entered the world of OnlyFans as a friend helping
                    out another friend who was struggling to gain traction on
                    the platform. With my background in marketing and social
                    media management, I saw an opportunity to help my friend
                    grow her account and increase her earnings.
                  </p>
                  <p>
                    Through trial and error, we developed a winning content
                    strategy that resonated with her audience, and before long,
                    her account was among the top on the platform. Word of mouth
                    spread, and soon we were receiving requests from other
                    creators who wanted our help.
                  </p>
                  <p>
                    What sets us apart from other OnlyFans management agencies
                    is our unwavering commitment to our creators' brands. We
                    understand that every creator is unique and has their own
                    story to tell. That's why we take the time to get to know
                    our clients, understand their brand and their goals, and
                    develop a customized plan to help them succeed.
                  </p>
                  <p>
                    Unlike other agencies that are solely profit-driven, we are
                    motivated by a genuine desire to see our creators thrive. We
                    believe that when our creators succeed, we succeed, and
                    that's why we go above and beyond to provide the best
                    possible service to our clients.
                  </p>
                  <p>
                    So if you're a creator looking to take your OnlyFans account
                    to the next level, look no further. Our team is here to help
                    you achieve your goals and build a successful brand on the
                    platform. Let's get started today!
                  </p>
                  <Link to="#" className="axil-btn btn-fill-primary btn-large">
                    Read More
                  </Link>
                </div>
                {/* <div className="case-study-counterup">
                  <div className="single-counterup">
                    <h2 className="count-number">
                      <TrackVisibility once>
                        {({ isVisible }) => (
                          <span className="number count">
                            {isVisible ? (
                              <CountUp end="10" duration={1} />
                            ) : null}
                          </span>
                        )}
                      </TrackVisibility>
                      <span className="symbol">+</span>
                    </h2>
                    <span className="counter-title">Years on the market</span>
                  </div>
                  <div className="single-counterup">
                    <h2 className="count-number">
                      <TrackVisibility once>
                        {({ isVisible }) => (
                          <span className="number count">
                            {isVisible ? (
                              <CountUp end="1500" duration={1} />
                            ) : null}
                          </span>
                        )}
                      </TrackVisibility>
                      <span className="symbol">+</span>
                    </h2>
                    <span className="counter-title">
                      Projects delivered so far
                    </span>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default AboutFour;