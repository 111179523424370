import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderTwo';
import SEO from '../common/SEO';
import BannerTwo from '../component/banner/BannerTwo';
import BlogOne from '../component/blog/BlogOne';


import SplashCta from "../component/cta/SplashCta";



import ServicePropOne from '../component/service/ServicePropOne';
import TestimonialOne from '../component/testimonial/TestimonialOne';
import SectionTitle from '../elements/section-title/SectionTitle';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import AboutOne from '../component/about/AboutOne';


import CounterUpTwo from "../component/counterup/CounterUpTwo";
import FaqOne from "../component/faq/FaqOne";





const Home = () => {


    return (
      <>
        <SEO title="Boost Your OnlyFans Presence with myOnly - The Ultimate Management Agency" />
        <ColorSwitcher />
        <main className="main-wrapper">
          <HeaderOne />
          <BannerTwo
            title="Maximize your profits and minimize your stress."
            subTitle="We brand people, not businesses."
            cta="Get in touch"
            image={ process.env.PUBLIC_URL + "/images/hero/myOnly-herobanner.jpg" }
          />

          <div className="section section-padding">
            <div className="container">
              <SectionTitle
                subtitle="What We Can Do For You"
                title="Services we can <br> help you with"
                description=""
                textAlignment="heading-left mb--20 mb_md--70"
                textColor=""
              />
              <div className="row">
                <ServicePropOne
                  colSize="col-lg-6"
                  serviceStyle="service-style-2"
                  itemShow="4"
                  marginTop="yes"
                />
              </div>
            </div>
            <ul className="shape-group-7 list-unstyled">
              <li className="shape shape-1">
                <img
                  src={process.env.PUBLIC_URL + "/images/others/circle-2.png"}
                  alt="circle"
                />
              </li>
              <li className="shape shape-2">
                <img
                  src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"}
                  alt="Line"
                />
              </li>
              <li className="shape shape-3">
                <img
                  src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"}
                  alt="Line"
                />
              </li>
            </ul>
          </div>
          <AboutOne />
       
          {/* <ProjectOne parentClass="bg-color-light" /> */}

          <CounterUpTwo />

          <BannerTwo
            subTitle="Refer a friend to myOnly and receive 15% of our profits as a reward."
            cta="Find out more"
            image={
              process.env.PUBLIC_URL + "/images/hero/myOnly-herobanner-2.jpg"
            }
          />

          {/* <BannerTwo title={"hello"} cta={"hello"} /> */}
          <TestimonialOne />
          <BlogOne />
          <FaqOne />
          <SplashCta />
          <FooterOne parentClass="" />
        </main>

      </>
    );
}

export default Home;

