import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';

import ProjectOne from '../component/project/ProjectOne';
import SplashCta from "../component/cta/SplashCta";

// import CounterUpTwo from "../component/counterup/CounterUpTwo";


const Jobs = () => {

    return (
      <>
        <SEO title="Project Three Column" />
        <ColorSwitcher />
        <main className="main-wrapper">
          <HeaderOne />
          <BcrumbBannerOne
            title="We're Hiring!"
            paragraph="We always aim to hire the best of the best. That is why our salary and bonus are above market rate."
            styleClass=""
            mainThumb="/images/banner/banner-thumb-1.png"
          />
          {/* <CounterUpTwo /> */}
          <ProjectOne colSize="col-xl-4 col-md-6" columnGap="row-15" />
          <SplashCta />
          <FooterOne parentClass="" />
        </main>
      </>
    );
}

export default Jobs;