import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderTwo from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';

import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import ProcessOne from '../component/process/ProcessOne';
import AboutThree from '../component/about/AboutThree';
import AboutFour from '../component/about/AboutFour';

import SplashCta from "../component/cta/SplashCta";


const AboutUs = () => {

    return (
      <>
        <SEO title="About us" />
        <ColorSwitcher />
        <main className="main-wrapper">
          <HeaderTwo />
          <BcrumbBannerOne
            title="Alone we can do so little; together we can do so much."
            paragraph="We are proud to be one of the fastest growing OnyFans Management Agency"
            styleClass="thumbnail-4"
            mainThumb="/images/banner/banner-thumb-3.png"
          />
          <AboutFour />
          <AboutThree />
          {/* <AboutFive /> */}
          <ProcessOne />
          <SplashCta />
          <FooterOne parentClass="" />
        </main>
      </>
    );
}

export default AboutUs;